// Add your JS customizations here

jQuery(function($) {
    $('.products-slider-section').each(function() {
        var $section = $(this); // Store the current section
    
        $section.find('.products-slider').each(function() {
            var $sliderElement = $(this);
            var slider = UIkit.slider($sliderElement);
    
            // Attach event listeners to the previous and next buttons within this slider
            $sliderElement.closest('.products-slider-section').find('.slider-prev').click(function(e) {
                e.preventDefault();
                slider.show('previous');
            });
    
            $sliderElement.closest('.products-slider-section').find('.slider-next').click(function(e) {
                e.preventDefault();
                slider.show('next');
            });
        });
    });    
});